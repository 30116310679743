.icon {
	display: block;
	width: 1em;
	height: 1em;
	transform: translate3d(0,0,0);

	&.icon--flip {
		transform: translate3d(0,0,0) rotate(180deg);
	}
}

.mr {
	display: block;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	.mr--fit {
		position: absolute;
		z-index: 1;
		max-height: 100%;
	    max-width: 100%;
	    width: auto;
	    height: auto;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
		margin: auto;
		transform: translate3d(0,0,0);
	}

	.mr--full {
		position: absolute;
		z-index: 1;
		top: 0;
	    left: 0;
	    width: 100%;
		height: 100%;
		transform: translate3d(0,0,0);
	}

	img.mr--full {
		object-fit: cover;
	}

	&:before {
		content: '';
		display: block;
	}

	&.mr--16by9:before { padding-bottom: 56.3%; }
	&.mr--4by3:before { padding-bottom: 75%; }
	&.mr--3by2:before { padding-bottom: 66%; }
	&.mr--1by1:before { padding-bottom: 100%; }
	&.mr--2by1:before { padding-bottom: 50%; }
	&.mr--brand:before { padding-bottom: 26%; }

	&.mr--banner:before {
		padding-bottom: 50%;

		@media (max-width : 575px) {
			padding-bottom: 150%;
		}
	}
}

.brand {
    display: block;
    transform: translate3d(0,0,0);
    color: $blue !important;
    flex: 0 0 210px;

    @media (max-width : 1399px) {
        flex: 0 0 200px;
    }

    @media (max-width : 1199px) {
		flex: 0 0 190px;
		width: 190px;
    }

    @media (max-width : 767px) {
		flex: 0 0 170px;
		width: 170px;
    }

    @media (max-width : 575px) {
		flex: 0 0 150px;
		width: 150px;
    }

    @media (max-width : 410px) {
		flex: 0 0 139px;
		width: 139px;
    }
}

.alert {
	position: fixed;
	top: 2.08vw;
	left: 50%;
	padding: $sp-30 ($sp-25 * 2);
	width: 540px;
	font-weight: bold;
	line-height: 1.35em;
	text-align: center;
	color: $white;
	border-radius: 9px;
	transform: translate(-50%,-200%);
	user-select: none;
	z-index: 199;
	transition: transform 300ms ease, box-shadow 300ms ease, background 300ms ease;
	box-shadow: none;
	will-change: transform, box-shadow;
	pointer-events: none;

	@media (max-width : 991px) {
		top: 3.04vh;
	}

	@media (max-width : 575px) {
		padding: $sp-20 ($sp-20 * 2);
		top: 4vh;
		width: 84%;
	}

	&.is--visible {
		transform: translate(-50%,0);
		box-shadow: 0 0 75px rgba($black, .5);
		pointer-events: auto;

		& + .alert--shadow {
			opacity: 1;
		}
	}

	&.alert--success { background: $green; }
	&.alert--error { background: $red; }
	&.alert--info { background: $light; }
}

.btn {
	position: relative;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: 42px;
	padding: .5rem 3rem;
	user-select: none;
	font-size: .9rem;
	font-weight: 600;
	line-height: 1.5em;
	text-transform: uppercase;
	border: none;
	background: $light;
	color: $white !important;
	overflow: hidden;
	transition: transform 100ms ease;

	@media (min-width : 992px) {
		// padding: .6rem 3rem;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 101%;
		height: 100%;
		background: $blue;
		transform: translate3d(-105%,0,0);
		transition: transform 500ms ease;
	}

	&:active {
		transform: translate3d(0,0,0) scale(.9);
	}

	@media (hover: hover) {
		&:hover {
			&:before {
				transform: translate3d(0,0,0);
			}
		}
	}
}

.product--i {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100%;
	padding: calc(var(--sc-pd-lg) / 2) 0;
	padding-right: $sp-15 * 3;
	border-bottom: 1px solid $gray-border;

	@media (max-width : 991px) {
		padding-right: $sp-30;
	}

	@media (max-width : 767px) {
		padding: calc(var(--sc-pd-lg) / 3) 0;
	}

	.product--i_isset {
		display: flex;
		align-items: center;
		color: $blue !important;
		cursor: zoom-in;

		@media (hover : hover) {
			&:hover {
				color: $light !important;

				.mr {
					background: $light;
				}

				.mr--full {
					transform: translate3d(0,0,0) scale(0.9);
				}
			}
		}
		
		.mr {
			align-self: flex-start;
			flex: 0 0 125px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 1.5rem;
			background: $gray-bg;
			transition: background 300ms ease;

			@media (max-width : 1399px) {
				flex: 0 0 100px;
				margin-right: 1rem;
			}

			@media (max-width : 767px) {
				flex: 0 0 80px;
			}

			@media (max-width : 575px) {
				flex: 0 0 65px;
			}
		}

		.mr--full {
			border-radius: 50%;
			transition: transform 300ms ease;
		}

		h3 {
			font-weight: 500;
			margin: 0;
			color: currentColor;

			@media (max-width : 767px) {
				font-weight: 600;
			}

			&:only-child {
				padding: 1rem 0;
			}
		}

		p {
			margin: .8rem 0 1rem;
			font-size: .9rem;
			color: $body-color;

			@media (max-width : 575px) {
				margin: .5rem 0 .9rem;
			}
		}
	}

	.btn {
		margin: 0 0 0 calc(1.5rem + 125px);
		min-height: auto;
		font-size: .8rem;
		padding: .5rem 1rem;
		align-self: flex-start;

		@media (max-width : 1399px) {
			margin-left: calc(1rem + 100px);
		}
		
		@media (max-width : 767px) {
			padding: .4rem .8rem;
			margin-left: calc(1rem + 80px);
		}

		@media (max-width : 575px) {
			margin-left: calc(1rem + 65px);
		}

		& ~ a {
			position: absolute;
			width: 0;
			height: 0;
		}
	}
}

.swiper-arrow {
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12%;
	height: 100%;
	color: $white;
	font-size: 3rem;
	cursor: pointer;
	z-index: 2;
	transition: color 300ms ease, opacity 300ms ease;

	@media (max-width : 1199px) {
		font-size: 2.5rem;
	}

	@media (max-width : 991px) {
		display: none;
	}

	@media (hover: hover) {
		opacity: .33;

		&:hover {
			color: $light !important;
		}
	}
}

.swiper-container:hover {
	@media (hover: hover) {
		.swiper-arrow {
			opacity: 1;
		}
	}
}

.swiper-arrow-prev {
	left: 0;
}

.swiper-arrow-next {
	right: 0;
}

.swiper-dots {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	bottom: $sp-30;
	width: 100%;
	z-index: 1;
	pointer-events: none;
	transition: background 300ms ease;

	@media (min-width : 992px) {
		display: none;
	}
}

.swiper-dot {
	display: block;
	width: 9px;
	height: 9px;
	margin: 0 4px;
	border-radius: 50%;
	background: $white;
	pointer-events: auto;
}

.swiper-dot_active {
	background: $light;
}

.lead,
.fancybox-caption__body strong {
	font-size: 1.1rem;
	font-weight: 600;
}

.fancybox-caption__body strong {
	display: block;
	padding-bottom: .33em;
}

.fancybox-bg {
	background: $blue;
}

.fancybox-is-open .fancybox-bg {
	opacity: .96;
}

.fancybox-caption {
	background: linear-gradient(to top, rgba($blue, 0.85) 0%, rgba($blue, 0.3) 50%, rgba($blue, 0.15) 65%, rgba($blue, 0.075) 75.5%, rgba($blue, 0.037) 82.85%, rgba($blue, 0.019) 88%, rgba($blue, 0) 100%);
}

.fancybox-button {
	background: $blue;
}