#app:not(.index) {
    .header {
        padding-bottom: 0;
    }
}

.index {
    .header {
        @media (min-width : 1536px) {
            background: $gray-bg;
        }
    }
}

.about {
    .header {
        background: $gray-bg;
    }
}

.contact {
    .footer {
        background: none;
    }
}

.error {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .sc {
        flex-grow: 1;
    }
}