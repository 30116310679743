.form-group {
	position: relative;
	margin-bottom: 1rem;
}

.form-control {
	background: none !important;
	color: $blue !important;
	font-family: $ff-default;
	font-size: 16px;
	border: 1px solid $light !important;
	border-radius: $radius;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	transition: border 300ms ease, color 300ms ease, box-shadow 300ms ease;
	box-shadow: none !important;
    height: 42px;
    padding: 0.5rem 1rem;

	&:focus {
		border-color: $blue !important;
		box-shadow: 0 0 0 0.2rem rgba($blue, 0.25) !important;
	}
}

.form-select {
	.form-control {
		padding-right: 50px !important;
		
		&:invalid {
			font-size: 90%;
			font-weight: 500;
			color: $body-color !important;
		}
	}		
}

select::-ms-expand { display: none; }

textarea.form-control {
	height: auto;
	min-height: 126px;
	resize: none;
}

::placeholder {
	font-size: 90%;
	font-weight: 500;
	color: $body-color !important;
}

:-webkit-autofill,
:-webkit-autofill:hover {
	-webkit-text-fill-color: $blue;
	box-shadow: 0 0 0px 1000px $body-bg inset !important;
	transition: background-color 5000s ease 0s, border 300ms ease, color 300ms ease, box-shadow 300ms ease;
}

:-webkit-autofill:focus {
	-webkit-text-fill-color: $blue;
	box-shadow: 0 0 0px 1000px $body-bg inset, 0 0 0 0.2rem rgba($blue, 0.25) !important;
	transition: background-color 5000s ease 0s, border 300ms ease, color 300ms ease, box-shadow 300ms ease;
}

.was-validated {
	.form-control:invalid {
		color: $red !important;
		border-color: $red !important;

		&:focus {
			box-shadow: 0 0 0 0.2rem rgba($red, 0.25) !important;
		}

		&::placeholder {
			font-size: 90%;
			font-weight: 500;
			color: $red !important;
		}
	}
}