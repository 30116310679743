.header {
    position: relative;
    z-index: 19;
    padding: var(--hd-pd, 6vw) 0;
}

.header--actions {
    font-size: 1.75rem;

    a {
        display: block;
        padding: .25em;
    }

    button {
        margin-right: -0.5em;
        padding: 0 .5em;
        border: none;
        background: none;
        transition: transform 300ms ease, color 300ms ease;

        i {
            display: block;
            margin: 0 auto;
            width: 1.8rem;
            height: 3px;
            background: currentColor;
            transition: transform 300ms ease, opacity 300ms ease;

            &:nth-child(2) {
                margin: 6px auto;
            }
        }
    }

    a,
    button {
        color: $blue !important;

        @media (hover : hover) {
            &:hover {
                color: $light !important;
            }
        }
    }
}

.header--nav {
    .container {
        @media (min-width : 768px) {
            max-width: 100%;
            padding: 0;
        }

        @media (max-width : 767px) {
            opacity: 0;
            transition: opacity 500ms linear;
        }
    }

    @media (max-width : 767px) {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: calc(10vw + 45px);
        padding-bottom: 5vw;
        background: $white;
        transform: translate3d(0,-100%,0);
        transition: transform 500ms ease 300ms, box-shadow 300ms ease 300ms;
    }

    @media (max-width : 575px) {
        padding-top: calc(10vw + 39px);
        padding-bottom: 0;
    }
}

.header--nav--items {
    @media (max-width : 575px) {
        padding: 2vw 0 7vw;
    }

    > li {
        > a {
            display: block;
            padding: .4rem 0;
            font-weight: 500;
            color: $gray !important;

            @media (max-width : 575px) {
                font-weight: 400;
                padding: .5rem 0;
                font-size: 1.1rem;
                line-height: 1em;
            }

            @media (hover: hover) {
                &:hover {
                    color: $light !important;
                }
            }

            span {
                display: inline-block;
                padding: .15em 0;
                border-top: 2px solid rgba($white,0);
                border-bottom: 2px solid rgba($white,0);

                @media (max-width : 575px) {
                    padding: .25em 0;
                }
            }
        }

        &.is--active {
            > a {
                font-weight: bold;
                color: $blue !important;

                span {
                    border-bottom-color: $blue;
                }
            }
        }

        & + li {
            margin-left: 3rem;

            @media (max-width : 1199px) {
                margin-left: 2.5rem;
            }

            @media (max-width : 767px) {
                margin-left: 1.75rem;
            }

            @media (max-width : 575px) {
                margin: .15rem 0 0;
            }
        }
    }
}

.header--right {
    position: relative;

    @media (min-width : 992px) {
        border-left: 2px solid $blue;
        margin-left: 2.5rem;
        padding-left: 2.5rem;
    }

    @media (min-width : 1200px) {
        margin-left: 3rem;
        padding-left: 3rem;
    }

    @media (max-width : 991px) and (min-width : 768px) {
        position: absolute;
        top: $sp-15 / 2;
        right: calc((100vw - 690px) / 2);
    }

    @media (max-width : 767px) and (min-width : 576px) {
        border-left: 2px solid $blue;
        margin-left: 1.75rem;
        padding-left: 1.75rem;
    }
}

.header--sac {
    display: block;
    color: $blue !important;
    font-size: .95rem;
    font-weight: 500;

    @media (max-width : 991px) and (min-width : 768px) {
        border-left: 2px solid $blue;
        padding-left: 1rem;
        margin: 0 0 0 1rem;
        font-size: .9rem;
    }

    @media (max-width : 575px) {
        margin: 0 -6vw;
        padding: 5vw 6vw;
        font-size: 1.1rem;
        background: $light;
        color: $white !important;
    }
}

.header--social {
    margin: -0.4rem 0 0 -0.4rem;

    @media (max-width : 991px) {
        margin: 0;
    }

    @media (max-width : 767px) {
        margin: -0.2rem 0 0 -0.4em;
    }

    @media (max-width : 575px) {
        position: absolute;
        top: calc(0px - 7vw - 2.6rem);
        right: -0.5rem;
        margin: 0;
    }

    a {
        font-size: 1.2rem;
        display: block;
        padding: .4rem;
        color: $blue !important;

        @media (max-width : 991px) {
            padding: .2rem .3rem;
            font-size: 1.1rem;
        }

        @media (max-width : 767px) {
            font-size: 1.2rem;
            padding: .4rem;
        }

        @media (max-width : 575px) {
            font-size: 1.3rem;
            padding: .5rem;
        }

        @media (hover: hover) {
            &:hover {
                color: $light !important;
            }
        }
    }
}