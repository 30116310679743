.footer {
    background: $gray-bg;
    padding: 4vw 0;

    @media (max-width : 1399px) {
        padding: 5vw 0;
    }

    @media (max-width : 991px) {
        padding: 6vw 0;
    }

    @media (max-width : 767px) {
        padding: 10vw 0;
    }
    
    @media (max-width : 575px) {
        padding: 12vw 0;
    }

    .brand {
        margin-right: 4rem;

        @media (max-width : 991px) {
            margin-right: 3rem;
            flex: 0 0 auto;
        }

        @media (max-width : 575px) {
            width: 150px;
        }
    }
}

.footer--mail {
    display: inline-block;
    color: $blue !important;
    font-size: 1.1rem;

    @media (max-width : 767px) {
        font-size: 1.15rem;
    }

    @media (max-width : 575px) {
        font-size: 1.1rem;
    }

    &:after {
        content: '';
        display: block;
        width: 1.25rem;
        height: 1px;
        background: $blue;
        margin: .9rem 0;
    }
}

.footer--p {
    font-size: .9rem;

    @media (max-width : 767px) {
        font-size: 1rem;
    }
    
    @media (max-width : 575px) {
        font-size: .9rem;
        margin-right: 1.25rem !important;
        margin-bottom: 1rem !important;
    }
    
    b {
        font-weight: 600;
    }

    & + p {
        margin-left: 4rem;
        padding-left: 1.5rem;
        border-left: 3px solid $light;

        @media (max-width : 991px) {
            padding-left: 1.25rem;
            margin-left: 3rem;
        }

        @media (max-width : 575px) {
            margin: 0;
        }

        @media (max-width : 410px) {
            flex: 1 1 100%;
            padding-left: 1rem;
        }
    }
}

.footer--social {
    margin: 0 -0.3em;
    padding: .9rem 0;
    font-size: 1.2rem;

    @media (max-width : 767px) {
        padding: 1em 0;
        font-size: 1.4rem;
    }

    a {
        display: block;
        padding: .3em;
        color: $blue !important;
        

        @media (hover: hover) {
            &:hover {
                color: $light !important;
            }
        }
    }
}

.footer--small {
    font-size: .8rem;

    @media (max-width : 767px) {
        font-size: .9rem;
        margin-top: 2rem;
    }

    @media (max-width : 575px) {
        font-size: .8rem;
        margin-top: 1.25rem;
    }
}