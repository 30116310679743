.sc--pd {
    padding-top: var(--sc-pd-lg);
    padding-bottom: var(--sc-pd-lg);
}

.sc--banner {
    @media (min-width : 1536px) {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            top: -1px;
            left: 0;
            height: 50%;
            background: $gray-bg;
            position: absolute;
            z-index: -1;
        }
    }

    .container {
        padding: 0;
        max-width: 100%;

        @media (min-width : 1536px) {
            max-width: 1440px;
        }
    }
}

.sc--index {
    width: 100%;
    overflow: hidden;

    .info {
        &:before {
            content: '';
            display: block;
            height: $sp-10;
            position: absolute;
            top: 0;
            left: $sp-15;
            right: -$sp-15;
            background: $light;

            @media (max-width : 1199px) {
                right: 53%;
            }
            
            @media (max-width : 991px) {
                right: 59%;
            }
            
            @media (max-width : 575px) {
                height: $sp-10 / 2;
            }
        }

        &:after {
            content: '';
            display: block;
            height: $sp-10;
            position: absolute;
            bottom: 0;
            left: calc(20% + 1.3rem + 15px);
            right: -$sp-15;
            background: $blue;

            @media (max-width : 1399px) {
                left: calc(12% + 1.3rem + 15px);
            }

            @media (max-width : 1199px) and (min-width : 768px) {
                display: none;
            }
            
            @media (max-width : 767px) {
                right: calc(16% + 15px);
                left: calc(1.3rem + 15px);
            }
            
            @media (max-width : 575px) {
                right: auto;
                width: 24%;
                height: $sp-10 / 2;
            }
        }
    }
    
    .video {
        @media (max-width : 1199px) {
            padding-top: 0;
        }
    }
    
    h2 {
        margin-bottom: calc(var(--sc-pd-lg) / 2);
        font-weight: normal;

        @media (max-width : 767px) {
            margin-bottom: var(--sc-pd-lg);
            font-size: $fs-h1 - 0.6;
        }

		@media (max-width : 575px) {
			font-size: $fs-h1 - 0.8;
		}

        b {
            font-weight: 800;
        }
    }
    
    .content {
        position: relative;
        padding-left: 1.7rem;
        margin: 0 6% 0 20%;

        @media (max-width : 1399px) {
            margin: 0 5% 0 12%;
        }

        @media (max-width : 1199px) {
            margin: 0 5% 0 0;
        }

        @media (max-width : 1199px) {
            margin: 0 0 0 8%;
        }

        @media (max-width : 767px) {
            margin: 0 16% 0 0;
        }
        
        @media (max-width : 575px) {
            margin: 0 8% 0 0;
        }

        &:before {
            content: '';
            display: block;
            width: 3px;
            height: 3.5rem;
            background: $light;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .mr {
        @media (min-width : 1200px) {
            z-index: 1;
            padding-bottom: 56.3%;

            &:before {
                position: absolute;
                bottom: 0;
                left: 100%;
                margin-left: 2rem;
                z-index: -1;
                padding: 0;
                height: 3px;
                width: calc((100vw - 1140px + 30px - 8rem) / 2);
                background: $light;

            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                bottom: 100%;
                left: 100%;
                width: calc(var(--sc-pd-lg) * 1.5);
                height: calc(var(--sc-pd-lg) * 1.5);
                background: url('../../img/site/pattern-bg.png') center repeat;
                background-size: 70px 59px;
                transform: translate3d(-50%,50%,0);
            }
        }

        @media (min-width : 1400px) {
            &:before {
                width: calc((100vw - 1320px + 30px - 8rem) / 2);
            }
        }

        @media (max-width : 767px) {
            margin: 0 calc(0px - ((100vw - 540px + 30px) / 4)) var(--sc-pd-lg);
        }
        
        @media (max-width : 575px) {
            margin: 0 -3vw var(--sc-pd-lg);
        }

        img {
            z-index: 1;
        }

        a {
            color: $white;
            font-size: 5rem;
            z-index: 2;

            @media (max-width : 375px) {
                font-size: 4rem;
            }

            .icon {
                transition: opacity 300ms ease, transform 300ms ease;
            }

            @media (hover: hover) {
                &:hover {
                    background: rgba($blue, .5);
                }
            }
        }

        iframe {
            z-index: 3;
            background: $black;
        }
    }
}

.sc--products-link {
    background: $light;

    a {
        position: relative;
        color: $blue !important;
        padding: var(--sc-pd-sm) 0;

        .icon {
            fill: $white;
            transition: transform 300ms ease;
            transform: translate3d(0,0,0) rotate(90deg);
            
            @media (min-width : 768px) {
                position: absolute;
                bottom: 0;
                left: 50%;
                height: calc(var(--sc-pd-sm) * 1.3);
                padding: calc(var(--sc-pd-sm) / 3) 0;
                transform: translate3d(-50%,0,0) rotate(270deg);
            }

            @media (max-width : 767px) {
                flex-shrink: 0;
                margin-left: 30px;
            }
        }

        &:hover {
            .icon {
                @media (hover: hover) and (min-width : 768px) {
                    transform: translate3d(-50%,8%,0) rotate(270deg);
                }

                @media (hover: hover) and (max-width : 767px) {
                    transform: translate3d(0,-25%,0) rotate(90deg);
                }
            }
        }
    }
}

.sc--about {
    width: 100%;
    overflow: hidden;

    h1 {
        position: relative;
        z-index: 1;
        margin: 0 0 calc(var(--sc-pd-lg) / 2);
        padding-bottom: calc(var(--sc-pd-lg) / 2);

        @media (max-width : 767px) {
            margin-bottom: 0;
            padding-bottom: var(--sc-pd-lg);
        }

        span {
            @media (min-width : 1200px), (max-width : 767px) and (min-width : 421px) {
                font-size: 1.15em;
                line-height: 1.15em;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: -100vw;
            width: 200vw;
            height: 100vw;
            background: $gray-bg;
            z-index: -1;
        }
    }

    p {
        border-left: 3px solid $light;
        padding-left: 1.5rem;
        margin: 0 6% 0 20%;

        @media (max-width : 1199px) {
            margin-left: 0;
        }

        @media (max-width : 767px) {
            margin: 0 25% 0 0;
        }

        @media (max-width : 575px) {
            margin: 0 8% 0 0;
        }
    }

    .mr {
        min-height: 100%;

        @media (max-width : 991px) {
            margin-right: calc(0px - ((100vw - 720px + 30px) / 2));
        }

        @media (max-width : 767px) {
            z-index: 1;
            min-height: 1px;
            margin: 0 calc(0px - ((100vw - 540px + 30px) / 4)) var(--sc-pd-lg);

            &:after {
                content: '';
                display: block;
                height: 50%;
                width: 100vw;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -50vw;
                z-index: -1;
                background: $gray-bg;
            }
        }

        @media (max-width : 575px) {
            margin: 0 -3vw var(--sc-pd-lg);
        }
    }
}

.sc--about-2 {
    padding-bottom: calc(var(--sc-pd-lg) / 2);

    @media (max-width : 575px) {
        padding-bottom: 3vw;
    }

    .grid-container {
        @media (min-width : 768px) {
            display: grid;
            grid-template-columns: 28% 28% 22% 22%;
            grid-template-rows: auto auto auto;
            gap: 0px 0px;
            grid-template-areas:
                "img1 img1 content content"
                "img1 img1 content content"
                "img2 img2 img3 img3";
        }

        @media (max-width : 991px) {
            grid-template-columns: 25% 25% 25% 25%;
        }

        @media (max-width : 767px) {
            display: flex;
            flex-direction: column;   
        }
    }

    .row {
        margin: 0 !important;
        padding-left: $sp-30 * 2;

        @media (max-width : 1199px) {
            padding-left: $sp-20 * 2;
        }

        @media (max-width : 991px) {
            padding-left: 0;
        }

        > div {
            padding: $sp-30 0 0 $sp-30;

            @media (max-width : 1199px) {
                padding: $sp-20 0 0 $sp-20;
            }

            @media (max-width : 767px) {
                padding: 0 0 0 0;
            }
        }
    }

    .img1 {
        grid-area: img1;
        position: relative;
        z-index: 1;

        @media (max-width : 991px) {
            margin-left: calc(0px - ((100vw - 720px + 30px) / 2));
        }

        @media (max-width : 767px) {
            display: flex;
            flex-direction: row-reverse;
            order: 1;
            margin: 0 calc(0px - ((100vw - 540px + 30px) / 4));
        }

        @media (max-width : 575px) {
            margin: 0 -3vw;
        }

        &:before {
            @media (max-width : 767px) {
                content: '';
                display: block;
                background: $gray-bg;
                display: block;
                width: 100vw;
                position: absolute;
                z-index: -1;
                right: 16%;
                top: calc(25% - 7px);
                bottom: calc(0px - 15px - 25%);
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 100%;
            height: $sp-10;
            width: 33%;
            display: block;
            background: $light;

            @media (max-width : 767px) {
                left: auto;
                right: 0;
                width: 25%;
            }

            @media (max-width : 575px) {
                height: $sp-10 / 2;
            }
        }

        > .mr {
            z-index: 2;

            @media (min-width : 768px) {
                &:after {
                    content: '';
                    display: block;
                    background: $gray-bg;
                    display: block;
                    width: 100vw;
                    height: 50%;
                    position: absolute;
                    z-index: -1;
                    right: 0;
                    bottom: 0;
                }
            }

            @media (max-width : 767px) {
                flex: 0 0 66.6666%;
                min-height: 100%;

                &:before {
                    padding-bottom: 0;
                }
            }
        }

        > .row {
            @media (min-width : 768px) {
                position: relative;
                z-index: 1;

                &:before {
                    content: '';
                    display: block;
                    background: $gray-bg;
                    display: block;
                    width: 100vw;
                    position: absolute;
                    z-index: -1;
                    right: calc(25% - 30px);
                    top: -1px;
                    bottom: calc(0px - 50% - 15px);

                    @media (max-width : 991px) {
                        right: calc(25% - 20px);
                    }
                }
            }

            @media (max-width : 767px) {
                flex: 0 0 33.3333%;
                padding-right: $sp-15;

                > div:last-child {
                    padding-top: $sp-15;
                }
            }
        }
    }

    .img2 {
        grid-area: img2;
        position: relative;
        z-index: 1;

        @media (max-width : 991px) {
            margin-left: calc(0px - ((100vw - 720px + 30px) / 2));
        }

        @media (max-width : 767px) {
            margin: $sp-15 calc(0px - ((100vw - 540px + 30px) / 4)) 0;
            order: 2;
        }

        @media (max-width : 575px) {
            margin: $sp-15 -3vw 0;
        }
    }

    .img3 {
        grid-area: img3;
        margin-top: $sp-30;
        margin-left: 12%;

        @media (max-width : 1199px) {
            margin-top: $sp-20;
        }

        @media (max-width : 991px) {
            margin-right: calc(0px - ((100vw - 720px + 30px) / 2));
        }

        @media (max-width : 767px) {
            margin: var(--sc-pd-lg) calc(0px - ((100vw - 540px + 30px) / 4)) 0;
            order: 4;

            &:before {
                padding-bottom: 66% !important;
            }
        }

        @media (max-width : 575px) {
            margin: var(--sc-pd-lg) -3vw 0;
        }

        &:after {
            content: '';
            position: absolute;
            left: 100%;
            bottom: 0;
            width: $sp-10;
            height: 66%;
            display: block;
            background: $light;

            @media (max-width : 991px) and (min-width : 768px), (max-width : 575px) {
                width: 66%;
                height: $sp-10;
                left: auto;
                right: 0;
                bottom: 100%;
            }

            @media (max-width : 575px) {
                height: $sp-10 / 2;
            }
        }
    }

    .content {
        margin-left: 12%;
        grid-area: content;

        @media (max-width : 767px) {
            margin: calc(var(--sc-pd-lg) / 2) 0 0;
            order: 3;
        }
    }   

    .mr {
        background: $gray-bg;

        &:before {
            @media (max-width : 991px) {
                padding-bottom: 75%;
            }
        }
    }

    .h1 {
        span {
            @media (max-width : 1199px) and (min-width : 768px) {
                font-size: .85em;
                line-height: .85em;
            }
        }
    }

    hr {
        border: none;
        border-left: 3px solid $light;
        width: 2px;
        height: 5rem;
        margin: calc(var(--sc-pd-lg) / 4) 0;

        @media (max-width : 991px) and (min-width : 768px), (max-width : 575px) {
            height: 2.5rem;
        }
    }

    p {
        margin-right: 40%;

        @media (max-width : 1399px) {
            margin-right: 25%;
        }
        
        @media (max-width : 1199px) {
            margin-right: 16%;
        }

        @media (max-width : 991px) {
            margin-right: 0;
        }

        @media (max-width : 767px) {
            margin: 0 25% 0 0;
        }

        @media (max-width : 575px) {
            margin: 0 8% 0 0;
        }
    }
}

.sc--products {
    .heading {
        margin-bottom: calc(var(--sc-pd-lg) / 2);
        
        @media (min-width : 991px) {
            white-space: nowrap;
        }

        @media (max-width : 767px) {
            margin-bottom: var(--sc-pd-lg);
        }

        h1 {
            margin-bottom: .15rem;

            @media (min-width : 768px) and (max-width : 991px) {
                margin: -0.15rem 0 0;
                white-space: nowrap;
            }
        }

        hr {
            margin: .75rem 1.5rem;
            border-top-color: $blue;

            @media (max-width : 991px) {
                margin: .35rem 1.5rem;
                min-width: 100px;
            }

            @media (max-width : 767px) {
                margin: calc(var(--sc-pd-lg) / 2) 0;
                width: 150px;
            }
        }
    }

    .row {
        border-bottom: 1px solid $gray-border;
        
        @media (max-width : 767px) {
            border-top: 1px solid $gray-border;
        }

        > div {
            margin-bottom: -1px;

            @media (min-width : 1200px) {
                &:nth-child(3n) .product--i {
                    padding-right: 0;
                }
            }

            @media (max-width : 1199px) and (min-width : 768px) {
                &:nth-child(even) .product--i {
                    padding-right: 0;
                }
            }

            @media (max-width : 767px) {
                padding-right: 0;
            }
        }
    }
}

.sc--contact {
    color: $blue;

    hr {
        border-top: 1px solid $blue;

        @media (min-width : 768px) {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;

            &:not(.flex-grow-1) {
                max-width: 250px;
                margin: calc(var(--sc-pd-lg) / 3) auto !important;
            }
        }

        @media (max-width : 767px) {
            max-width: 150px;
            margin: calc(var(--sc-pd-lg) / 2) auto;
        }

        @media (max-width : 575px) {
            max-width: 75px;
        }
    }

    .phones {
        padding-top: .35em;
        font-size: 1.075em;
    }

    form {
        @media (min-width : 768px) {
            margin: calc(var(--sc-pd-lg) / 2) 0;
        }
    }

    ul {
        font-size: 2rem;

        @media (max-width : 575px) {
            font-size: 1.66rem;
        }

        a {
            display: block;
            padding: 0 .9rem;
            color: $blue !important;

            @media (max-width : 575px) {
                padding: 0 .66rem;
            }

            @media (hover: hover) {
                &:hover {
                    color: $light !important;
                }
            }
        }
    }

    .mr--map {
        height: 400px;
        margin-top: calc(var(--sc-pd-lg) / 2);
        background: $gray-bg;

        @media (max-width : 575px) {
            height: auto;

            &:before {
                padding-bottom: 100%;
            }
        }
    }

    .btn {
        @media (max-width : 420px) {
            width: 100%;
        }
    }
}