.is--nav {
    @media (max-width: 767px) and (min-width : 576px) {
        .header--nav {
            box-shadow: 0 0 100px rgba($black,0.08);
        }
    }

    @media (max-width: 767px) {
        .header--actions {
            button {
                transform: rotate(180deg);

                i {
                    &:nth-child(1) {
                        transform: translate3d(0,9px,0) rotate(-45deg);
                    }

                    &:nth-child(2) {
                        transform: scale(0);
                    }

                    &:nth-child(3) {
                        transform: translate3d(0,-9px,0) rotate(45deg);
                    }
                }
            }
        }

        .header--nav {
            transform: translate3d(0,0,0);
            transition: transform 500ms ease, box-shadow 300ms ease;

            .container {
                opacity: 1;
                transition: opacity 500ms linear;
            }
        }
    }
}

.is--playing {
    a {
        background: $black !important;

        .icon {
            filter: drop-shadow(0 0 10px rgba($black, .33));
            opacity: 0 !important;
            transform: scale(.5);
        }
    }
}